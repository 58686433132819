/* eslint jsx-a11y/media-has-caption: 'off' */
import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-unresolved
import VideoMp4 from '../video/showreel.2020.mp4';
// eslint-disable-next-line import/no-unresolved
import VideoWebm from '../video/showreel.2020.webm';
import { imageHeightConstant } from './util/text';

const Container = styled.article`
  background-color: black;
  color: white;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  min-height: 30vh;
  max-height: ${imageHeightConstant}vh;
  height: auto;
  overflow: hidden;
  position: relative;
  text-align: center;
`;

const VideoContainer = styled.video`
  width: 100%;
  /* height: 100%; */
  margin: 0 auto;
  min-height: 45vh;
  max-height: ${imageHeightConstant}vh;
  /* eslint-disable-next-line eslint-comments/no-unlimited-disable */
  object-fit: contain;

  &:focus {
    outline: 0;
  }
`;

const Button = styled.button`
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid white;
  color: white;
  margin: 0;
  padding: 0.25rem 0.5rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;

  &:focus {
    outline: 0;
  }

  &.disable {
    display: none;
  }
`;

// TODO: use navigator.connection for smaller video files? (lower quality?)

const Video = () => {
  const videoRef = useRef();
  const buttonRef = useRef();

  useEffect(() => {
    const videos = document.getElementsByTagName('video');
    if (videos && videos.length > 0) {
      videos[0].addEventListener(
        'play',
        () => {
          if (buttonRef && buttonRef.current)
            buttonRef.current.style.display = 'none';
        },
        true
      );
    }
  });

  return (
    <Container>
      <VideoContainer
        ref={videoRef}
        preload="metadata"
        controls
        controlsList="nodownload noremoteplayback"
      >
        <source src={VideoMp4} type="video/mp4"></source>
        <source src={VideoWebm} type="video/webm"></source>
        <p>
          Your browser doesn't support HTML5 video. Please try another browser.
        </p>
      </VideoContainer>
      <Button
        ref={buttonRef}
        onClick={() => {
          if (videoRef && videoRef.current) videoRef.current.play();
          if (buttonRef && buttonRef.current)
            buttonRef.current.style.display = 'none';
        }}
      >
        Play
      </Button>
    </Container>
  );
};

export default Video;
